.app-background {
  height: 100vh;
}

.app-header {
  font-size: calc(20px + 2vmin);
  color: #737373;
  background-color: white;
}

.app-content {
  background-color: white;
  color: #737373;
  flex-direction: column;
  display: flex;
  padding: 20px 0;
}

.app-footer {
  color: #737373;
  background-color: white;
}

.app-logo {
  height: 200px;
  width: 200px;
}
